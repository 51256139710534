export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: (context) => import('~/locales/vue-i18n.js').then(m => m.default(context)),
  vueI18nLoader: false,
  locales: [{"code":"ru","iso":"ru-KZ","dir":"ltr","lang":"русский","file":"ru.js","dayjsLocale":"ru","name":"Казахстан","country":"kz","storeId":"kz","languageCode":{"ru":"ru_RU","kk":"ru_KZ"},"currencySymbol":"₸","currencyCode":"KZT","baseUrl":"https://goldapple.kz"},{"code":"kk","iso":"kk-KZ","dir":"ltr","lang":"қазақша","file":"kk.js","dayjsLocale":"kk","name":"Казахстан","country":"kz","storeId":"kz","languageCode":{"ru":"ru_RU","kk":"ru_KZ"},"currencySymbol":"₸","currencyCode":"KZT","baseUrl":"https://goldapple.kz"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "~/locales/languages/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"ga-lang","cookieSecure":false,"fallbackLocale":"","redirectOn":"all","useCookie":true},
  differentDomains: false,
  baseUrl: "https://goldapple.kz",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ru","iso":"ru-KZ","dir":"ltr","lang":"русский","file":"ru.js","dayjsLocale":"ru","name":"Казахстан","country":"kz","storeId":"kz","languageCode":{"ru":"ru_RU","kk":"ru_KZ"},"currencySymbol":"₸","currencyCode":"KZT","baseUrl":"https://goldapple.kz"},{"code":"kk","iso":"kk-KZ","dir":"ltr","lang":"қазақша","file":"kk.js","dayjsLocale":"kk","name":"Казахстан","country":"kz","storeId":"kz","languageCode":{"ru":"ru_RU","kk":"ru_KZ"},"currencySymbol":"₸","currencyCode":"KZT","baseUrl":"https://goldapple.kz"}],
  localeCodes: ["ru","kk"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "r",
  3: "u",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "c",
  14: "l",
  15: "i",
  16: "e",
  17: "n",
  18: "t",
  19: "/",
  20: "l",
  21: "o",
  22: "c",
  23: "a",
  24: "l",
  25: "e",
  26: "s",
  27: "/",
  28: "l",
  29: "a",
  30: "n",
  31: "g",
  32: "u",
  33: "a",
  34: "g",
  35: "e",
  36: "s",
  37: "/",
  38: "r",
  39: "u",
  40: ".",
  41: "j",
  42: "s",
  43: "\"",
  44: ",",
  45: "\"",
  46: "k",
  47: "k",
  48: ".",
  49: "j",
  50: "s",
  51: "\"",
  52: ":",
  53: "\"",
  54: ".",
  55: ".",
  56: "/",
  57: "c",
  58: "l",
  59: "i",
  60: "e",
  61: "n",
  62: "t",
  63: "/",
  64: "l",
  65: "o",
  66: "c",
  67: "a",
  68: "l",
  69: "e",
  70: "s",
  71: "/",
  72: "l",
  73: "a",
  74: "n",
  75: "g",
  76: "u",
  77: "a",
  78: "g",
  79: "e",
  80: "s",
  81: "/",
  82: "k",
  83: "k",
  84: ".",
  85: "j",
  86: "s",
  87: "\"",
  88: "}",
}

export const localeMessages = {
  'ru.js': () => import('../../client/locales/languages/ru.js' /* webpackChunkName: "lang-ru.js" */),
  'kk.js': () => import('../../client/locales/languages/kk.js' /* webpackChunkName: "lang-kk.js" */),
}
